import axios from "axios";

export default {
	async get() {
		return await axios.get("/profile");
	},

	async updateApprovals(payload) {
		return await axios.put("/profile/approvals", payload);
	},

};