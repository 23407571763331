const state = {
	title: 'Tytuł strony',
	breadcrumbs: [],
};

const getters = {
	TITLE: state => state.title,
	BREADCRUMBS: state => state.breadcrumbs,
};

const mutations = {
	TITLE_SET(state, value) {
		state.title = value;
	},
	BREADCRUMBS_SET(state, items) {
		state.breadcrumbs = [
			{
				text: 'Dashboard',
				disabled: false,
				href: 'Dashboard',
			}
		];

		state.breadcrumbs.push(...items);
	},
};

const actions = {};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
