import DashboardService from "@/services/dashboard/DashboardService";
import ProductCategoryService from "@/services/product_category/ProductCategoryService";
import BasketService from "@/services/dashboard/BasketService";
import _ from 'lodash';
import Vue from "vue";

const state = {
	products: [],
	categories: [],
	categoriesInSummary: [],

	dls: [],

	activeDl: 0,
	retailPrice: 0,
	clientPrice: 0,

	goTo: null,

	loading: false,
	loadingUpdateBasket: false,
};

const getters = {
	GO_TO: state => state.goTo,

	LOADING: state => state.loading,
	LOADING_UPDATE_BASKET: state => state.loadingUpdateBasket,

	CATEGORIES: state => state.categories,
	CATEGORIES_IN_SUMMARY: state => state.categoriesInSummary,

	PRODUCTS: state => state.products,
	PRODUCTS_WITH_QUANTITY: state => state.products.filter(i => i.quantity > 0),
	PRODUCTS_FOR_SUMMARY: state => state.products.filter(i => i.quantity > 0 || i.type === 'category'),


	DLS: state => state.dls,
	ACTIVE_DL: (state, getters, rootState, rootGetters) => {
		if(rootGetters['AUTH_STORE/DL_FLEX'] !== null){
			return rootGetters['AUTH_STORE/DL_FLEX'];
		}
		for (let i = 0; state.dls.length > i; i++) {
			if (state.dls[i] > state.retailPrice) {
				return i;
			}
		}
		return state.dls.length;
	},
	RETAIL_PRICE: state => state.retailPrice,
	CLIENT_PRICE: state => state.clientPrice,
};

const mutations = {
	GO_TO_SET(state, value) {
		state.goTo = value;
	},

	LOADING_SET(state, value) {
		state.loading = value;
	},

	LOADING_UPDATE_BASKET_SET(state, value) {
		state.loadingUpdateBasket = value;
	},

	CATEGORIES_SET(state, value) {
		state.categories = value;
	},
	CATEGORIES_IN_SUMMARY_SET(state, value) {
		state.categoriesInSummary = value;
	},

	PRODUCTS_SET(state, value) {
		state.products = value;
	},
	PRODUCT_PRICE_UPDATE(state, {dl, id, value}) {
		let item = state.products.find(i => i.id === id && i.type === 'product')
		item.quantity = value
		item.total = item.quantity * item.prices[dl].price
	},

	DL_SET(state, value) {
		state.dls = value;
	},
	ACTIVE_DL_SET(state, value) {
		state.activeDl = value;
	},
	RETAIL_PRICE_SET(state, value) {
		state.retailPrice = value;
	},
	CLIENT_PRICE_SET(state, value) {
		state.clientPrice = value;
	},
};

const actions = {
	getCategories({getters, commit}) {
		if (!getters.CATEGORIES.length) {
			return new Promise((resolve, reject) => {
				ProductCategoryService.all()
					.then((resp) => {
						if (resp.data.success) {
							commit('CATEGORIES_SET', resp.data.data);
							commit('CATEGORIES_IN_SUMMARY_SET', resp.data.showInSummary);
						}
						resolve(resp);
					})
					.catch(err => {
						reject(err);
					});
			});
		}

	},

	getProducts({getters, commit}) {
		commit('LOADING_SET', true);
		return new Promise((resolve, reject) => {
			DashboardService.products()
				.then((resp) => {
					if (resp.data.success) {
						commit('DL_SET', resp.data.dls);
						commit('LOADING_SET', false);

						let products = resp.data.data;
						let retailPrice = 0;
						let clientPrice = 0;
						for (let i = 0; i < products.length; i++) {
							if (products[i].quantity > 0) {
								retailPrice += products[i].retailPrice.price * products[i].quantity;
							}
						}
						commit('RETAIL_PRICE_SET', retailPrice)

						for (let i = 0; i < products.length; i++) {
							if (products[i].type === 'product') {
								products[i].total = products[i].prices[getters.ACTIVE_DL].price * products[i].quantity;
								clientPrice += products[i].total;
							}
						}
						commit('CLIENT_PRICE_SET', clientPrice)
						commit('PRODUCTS_SET', products);
					}
					resolve(resp);
				})
				.catch(err => {
					commit('LOADING_SET', false);
					reject(err);
				});
		});
	},
	updateProductQuantity({commit, getters, dispatch}, {id, value, created}) {
		if (!created) {
			commit('PRODUCT_PRICE_UPDATE', {
				dl: getters.ACTIVE_DL,
				id: id,
				value: value
			});

			let data = getters.PRODUCTS_WITH_QUANTITY;
			let retailPrice = 0;
			let clientPrice = 0;
			for (let i = 0; i < data.length; i++) {
				retailPrice += data[i].retailPrice.price * data[i].quantity;
			}
			commit('RETAIL_PRICE_SET', retailPrice)

			for (let i = 0; i < data.length; i++) {
				clientPrice += data[i].prices[getters.ACTIVE_DL].price * data[i].quantity;
			}

			commit('CLIENT_PRICE_SET', clientPrice)

			commit('PRODUCT_PRICE_UPDATE', {
				dl: getters.ACTIVE_DL,
				id: id,
				value: value
			});

			if (!getters.LOADING_UPDATE_BASKET) {
				commit('LOADING_UPDATE_BASKET_SET', true)

				setTimeout(() => {
					commit('LOADING_UPDATE_BASKET_SET', false)
					dispatch('updateBasket')
				}, 500)
			}
		}
	},
	clearBasket({commit, getters, dispatch}) {

		let data = _.cloneDeep(getters.PRODUCTS_WITH_QUANTITY);
		for (let i = 0; i < data.length; i++) {
			commit('PRODUCT_PRICE_UPDATE', {
				dl: getters.ACTIVE_DL,
				id: data[i].id,
				value: 0
			});
		}

		commit('RETAIL_PRICE_SET', 0);
		commit('CLIENT_PRICE_SET', 0);
		commit('ACTIVE_DL_SET', 0);
		setTimeout(() => {
			dispatch('updateBasket')
		}, 10)
	},

	updateBasket({getters}) {
		return new Promise((resolve, reject) => {
			BasketService.update(getters.PRODUCTS_WITH_QUANTITY.map(i => {
				return {
					id: i.id,
					quantity: i.quantity,
				}
			}))
				.then((resp) => {
					let add = resp.data.filter(i => i.quantityChange > 0);
					let remove = resp.data.filter(i => i.quantityChange < 0);

					if (add.length) {
						Vue.$gtag.event(
							'add_to_cart',
							{
								items: add.map((item, index) => {
									return {
										index: index,
										item_id: item.id,
										item_name: item.name,
										item_brand: "WOOKAH",
										quantity: item.quantityChange,
										item_category: item.group ? item.group.name : '---',
									}
								}),
							}
						);
					}
					if (remove.length) {
						Vue.$gtag.event(
							'remove_from_cart',
							{
								items: remove.map((item, index) => {
									return {
										index: index,
										item_id: item.id,
										item_name: item.name,
										item_brand: "WOOKAH",
										quantity: item.quantityChange * -1,
										item_category: item.group ? item.group.name : '---',
									}
								}),
							}
						);
					}

					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});
	},

	getPromotion() {
		return new Promise((resolve, reject) => {
			DashboardService.getPromotion()
				.then((resp) => {
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});
	},

	getSpecialOffer() {
		return new Promise((resolve, reject) => {
			DashboardService.getSpecialOffer()
				.then((resp) => {
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
