import Vue from 'vue';
import axios from 'axios';
import store from '@/store';
import VueAxios from 'vue-axios';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.timeout = 120000;

axios.interceptors.request.use(
	function (config) {
		const token = window.localStorage.getItem("token");
		if (token != null) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		config.headers.common['X-Requested-With'] = 'XMLHttpRequest';
		return config;
	},
	function (error) {
		return Promise.reject(error.response);
	}
);

axios.interceptors.response.use((response) => {
	if (response.data.message && typeof response.data.success !== 'undefined') {
		if (response.data.success ) {
			Vue.swal({
				icon: 'success',
				title: 'Success!',
				text: response.data.message,
			});
		} else {
			Vue.swal({
				icon: 'error',
				title: 'Error!',
				text: response.data.message,
			});
		}
	}
	return response;
}, (error) => {
	//console.log(error)
	if (!error.response) {
		//store.dispatch('AUTH_STORE/logout');
		return Promise.resolve({error});
	}


	switch (error.response.status) {
		case 419:
			//store.dispatch('AUTH_STORE/logout');
			break;

		case 401:
			Vue.swal({
				icon: 'error',
				title: 'Your session has expired!',
				text: 'Please log in again',
			});
			store.dispatch('AUTH_STORE/unauthorized');
			break;
		default:
			if (error.response.data.messages != null) {
				Vue.swal({
					icon: 'error',
					title: 'Błąd!',
					text: error.response.data.messages,
				});
			} else {
				Vue.swal({
					icon: 'error',
					title: 'Error!',
					text: 'Something went wrong. Please try again later.',
				});
			}
	}

	return Promise.resolve({error});
});

Vue.prototype.$axios = axios;
Vue.use(VueAxios, axios);

export default new axios;
