import router from '@/router';
import Crsf from "@/services/Crsf";
import Auth from "@/services/user/Auth";
import UserService from "@/services/user/UserService";

const state = {
	loadingEmail: 0,

	authenticated: false,
	user: null,
	token: localStorage.getItem("token"),
	redirectTo: null,

	logoutStatus: true,

	testimonials_loading: false,
	testimonials: [],
};

const getters = {
	LOADING: state => state.loading,
	IS_AUTH: state => state.authenticated,
	USER: state => state.user,
	DL_FLEX: state => state.user ? state.user.dl_flex : null,
	PERMISSIONS: state => state.user ? state.user.permissions : [],
	REDIRECT_TO: state => state.redirectTo,

	TESTIMONIALS_LOADING: state => state.testimonials_loading,
	TESTIMONIALS: state => state.testimonials,
};

const mutations = {
	SET_LOADING(state, value) {
		state.loading += value;
	},
	SET_LOGOUT_STATUS(state, value) {
		state.logoutStatus = value;
	},

	SET_AUTHENTICATED(state, value) {
		state.authenticated = value;
	},
	SET_USER(state, value) {
		state.user = value;
	},
	SET_USER_APPROVAL(state, value) {
		state.user.approval = value;
	},
	CLEAR_USER() {
		localStorage.removeItem('token');
		state.user = null;
		state.authenticated = false;
		state.token = null;

	},

	SET_TOKEN(state, {token}) {
		state.token = token;
		localStorage.setItem('token', token);
	},

	SET_REDIRECT_TO(state, url) {
		state.redirectTo = url;
	},

	TESTIMONIALS_LOADING_SET(state, value) {
		state.testimonials_loading = value;
	},
	TESTIMONIALS_SET(state, value) {
		state.testimonials = value;
	},
};

const actions = {
	login({commit}, data) {
		commit('SET_LOADING', 1);
		return Crsf.getCookie()
			.then(() => {
				return Auth.login(data)
					.then(resp => {
						if (resp.data.success) {
							commit('SET_AUTHENTICATED', true);
							commit('SET_USER', resp.data.user);
							commit('SET_TOKEN', {
								token: resp.data.token,
							});
						} else {
							commit('CLEAR_USER');
						}
						commit('SET_LOADING', -1);
						return resp;
					}).catch(err => {
						commit('CLEAR_USER');
						commit('SET_LOADING', -1);
						console.error(err)
					});
			}).catch(err => {
				commit('SET_LOADING', -1);
				console.error(err)
			});

	},

	banners({commit, getters}) {
		return new Promise((resolve) => {
			if (!getters.TESTIMONIALS.length && !getters.TESTIMONIALS_LOADING) {
				commit('TESTIMONIALS_LOADING_SET', true);
				UserService.getBanners()
					.then(resp => {
						if (200 === resp.status && resp.data.success) {
							commit('TESTIMONIALS_SET', resp.data.data);
						}
						commit('TESTIMONIALS_LOADING_SET', false);
						resolve(resp);
					})
					.catch(err => {
						commit('TESTIMONIALS_LOADING_SET', false);
						console.error(err)
					});
			}
		});
	},

	autoLogin({commit, state}) {
		return new Promise((resolve) => {
			if (state.token) {
				UserService.getAuthUser()
					.then(resp => {
						if (200 === resp.status && resp.data.success) {
							commit('SET_USER', resp.data.data);
							commit('SET_AUTHENTICATED', true);
						}

						resolve();
					});
			} else {
				resolve();
			}
		});
	},

	logout({commit}) {
		localStorage.removeItem('showPromotion');
		if (state.token && state.logoutStatus) {
			commit('SET_LOGOUT_STATUS', false);
			Auth.logout().then(() => {
				router.push({name: 'Login'}).catch(() => {
				});
				commit('CLEAR_USER');
				commit('SET_LOGOUT_STATUS', true);
				//console.clear();
			});
		} else {
			commit('CLEAR_USER');
			router.push({name: 'Login'}).catch(() => {
			});
		}
	},

	unauthorized({commit}) {
		if (state.token && state.logoutStatus) {
			commit('SET_LOGOUT_STATUS', false);
			router.push({name: 'Login'}).catch(() => {
			});
			commit('CLEAR_USER');
			commit('SET_LOGOUT_STATUS', true);
		} else {
			commit('CLEAR_USER');
			router.push({name: 'Login'}).catch(() => {
			});
		}
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
