import Vue from 'vue'
import store from "@/store";
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'

import Auth from './auth';

import Info from './info';

import Orders from './orders';
import Profile from './profile';

import Other from './other';


Vue.use(VueRouter)
const routes = [
	{
		path: '',
		component: () => import('@/layouts/dashboard.vue'),
		meta: {
			requiresAuth: true,
			approval: true,
		},
		children: [
			{
				path: '/',
				component: () => import('@/views/Dashboard/Index'),
				name: 'Dashboard',
			},
		],
	},


	...Auth,
	...Info,
	...Profile,
	...Orders,
	...Other,

	{
		path: '/debug-sentry',
		beforeEnter: (to, from, next) => {
			console.error('TEST SENTRY: ' + new Date() + '!')
			next({name: 'Dashboard'})
			throw new Error('TEST SENTRY: ' + new Date() + '!');
		}
	},

	{
		path: '*',
		beforeEnter: (to, from, next) => {
			console.error('Not found: ' + to.fullPath + '!');
			next({name: 'Dashboard'})
		}
	},
];

const router = new VueRouter({
	mode: 'history',
	routes,
	base: process.env.BASE_URL,
	duplicateNavigationPolicy: 'ignore',
	scrollBehavior: (to, from, savedPosition) => {
		let scrollTo = 0

		if (to.hash) {
			scrollTo = to.hash
		} else if (savedPosition) {
			scrollTo = savedPosition.y
		}

		return goTo(scrollTo)
	},
});

router.beforeEach((to, from, next) => {
	if (localStorage.getItem('version') !== process.env.VUE_APP_API_VIERSION) {
		console.log('Refresh to new version!');
		localStorage.setItem('version', process.env.VUE_APP_API_VIERSION);
		window.location.reload();
	}

	if (to.matched.some(record => record.meta.requiresAuth) && !store.state['AUTH_STORE'].token) {
		next({name: "Login"});
	} else {
		if (
			to.matched.some(record => record.meta.approval)
			&& !store.state['AUTH_STORE'].user.approval
		) {
			next({name: "ProfileIndex"});
		} else {
			next();
		}
	}
});

export default router;
