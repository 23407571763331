import axios from "axios";

export default {
	async index() {
		return await axios.get("/register");
	},
	async storeStepTwo(payload) {
		return await axios.post("/register/step_two", payload);
	},
	async storeStepThree(payload) {
		return await axios.post(
			"/register/step_three",
			payload,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}
		);
	}
};