import UserService from "@/services/user/UserService";

const state = {};

const getters = {};

const mutations = {};

const actions = {
	updatePassword(state, payload) {
		return new Promise((resolve, reject) => {
			UserService.updatePassword(payload)
				.then(resp => {
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});
	},

};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
