import RegisterService from "@/services/user/RegisterService";

const state = {
	step: 1,
	stepCount: 3,

	back: false,

	email: '',

	dictionaries: {
		countries: [],
	},

	user: {
		email: null,
		name: null,
		street: null,
		postal_code: null,
		country: null,
		vatin: null,
		type: null,
	},
	address: {
		name: null,
		street: null,
		postal_code: null,
		country: null,
	},
	contact: {
		name: null,
		phone: null,
		email: null,
	},
};

const getters = {
	STEP: state => state.step,
	STEP_COUNT: state => state.stepCount,

	BACK: state => state.back,

	EMAIL: state => state.email,

	DICTIONARIES: state => state.dictionaries,

	USER: state => state.user,
	ADDRESS: state => state.address,
	CONTACT: state => state.contact,
};

const mutations = {
	STEP_SET(state, value) {
		state.step = value;
	},

	BACK_SET(state, value) {
		state.back = value;
	},

	EMAIL_SET(state, value) {
		state.email = value;
	},

	DICTIONARY_SET(state, {name, value}) {
		state.dictionaries[name] = value;
	},

	DATA_SET(state, {name, value}) {
		state[name] = value;
	},

};

const actions = {

	index({commit}) {
		return new Promise((resolve, reject) => {
			RegisterService.index()
				.then((resp) => {
					if (resp.data.success) {
						for (let name in resp.data.dictionaries) {
							commit('DICTIONARY_SET', {
								name: name,
								value: resp.data.dictionaries[name],
							});
						}
					}
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});
	},

	storeStepTwo({commit}, payload) {
		return new Promise((resolve, reject) => {
			RegisterService.storeStepTwo(payload)
				.then((resp) => {
					commit('EMAIL_SET', payload.user.email);
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});
	},

	storeStepThree(state, payload) {
		return new Promise((resolve, reject) => {
			RegisterService.storeStepThree(payload)
				.then((resp) => {

					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});
	},

};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
