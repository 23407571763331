import UserService from "@/services/user/UserService";

const state = {};

const getters = {};

const mutations = {};

const actions = {

	restorePasswordEmail(state, payload) {
		return new Promise((resolve, reject) => {
			UserService.restorePasswordEmail(payload)
				.then((resp) => {
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});
	},

	updateForgottenPassword(state, payload) {
		return new Promise((resolve, reject) => {
			UserService.updateForgottenPassword(payload)
				.then(resp => {
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});

	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
