import ClientService from "@/services/profile/ClientService";

const state = {
	moveTo: null,

	dictionaries: {
		countries: [],
	},

	client: {},
};

const getters = {
	MOVE_TO: state => state.moveTo,

	DICTIONARIES: state => state.dictionaries,

	CLIENT: state => state.client,

};

const mutations = {
	MOVE_TO_SET(state, value) {
		state.moveTo = value;
	},

	DICTIONARY_SET(state, {name, value}) {
		state.dictionaries[name] = value;
	},

	CLIENT_SET(state, value) {
		state.client = value;
	},


	UPDATE_VALUE_SET(state, {name, value}) {
		state.client[name] = value;
	},

};

const actions = {

	getClient({commit}) {
		return new Promise((resolve, reject) => {
			ClientService.get()
				.then((resp) => {
					if (resp.data.success) {
						commit('CLIENT_SET', resp.data.data);
						for (let name in resp.data.dictionaries) {
							commit('DICTIONARY_SET', {
								name: name,
								value: resp.data.dictionaries[name],
							});
						}
					}
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});
	},

	updateApprovals({dispatch, commit}, payload) {
		return new Promise((resolve, reject) => {
			ClientService.updateApprovals(payload)
				.then((resp) => {
					if (resp.data.success) {
						if (resp.data.approvals) {
							commit('AUTH_STORE/SET_USER_APPROVAL', resp.data.approvals, {root: true})
						}
						dispatch('AUTH_STORE/autoLogin', null, {root: true})
					}
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});
	},

};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
