import axios from "axios";

export default {
	async products() {
		return await axios.get("/products/dashboard");
	},

	async getPromotion() {
		return await axios.get("/promotion");
	},

	async getSpecialOffer() {
		return await axios.get("/special_offers");
	},
};
