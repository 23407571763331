import axios from "axios";

export default {
	async store(payload) {
		return await axios.post("/profile/contact_details", payload);
	},
	async update(id, payload) {
		return await axios.put("/profile/contact_details/" + id, payload);
	},
	async delete(id) {
		return await axios.delete("/profile/contact_details/" + id);
	},

};