const state = {
	items: [],
	menu: true,
};

const getters = {
	ITEMS: state => state.items,

	MENU: state => state.menu,

};

const mutations = {
	MENU_SET(state, value) {
		state.menu = value;
	},

	ITEMS_SET(state, value) {
		state.items = value;
	},
};

const actions = {


};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
