<template>
	<v-app>
		<transition
			name="fade"
			mode="out-in"
			:duration="500"
		>
			<router-view/>
		</transition>

		<v-snackbar
			v-model="cookies"
			max-width="600"
			:timeout="-1"
		>
			<div class="text-body-1">
				Our site uses cookies. For more information, see section 6. our
				<router-link to="#" class="blue--text font-weight-500">
					Privacy Policy
				</router-link>
				. Further use of the site means their acceptance.
			</div>

			<template v-slot:action="{ attrs }">
				<v-btn
					color="white"
					text
					large
					v-bind="attrs"
					@click="closeCookies"
				>
					OK
				</v-btn>
			</template>
		</v-snackbar>

	</v-app>
</template>
<script>

export default {
	name: 'App',
	components: {},
	computed: {},
	data: () => ({
		cookies: true
	}),
	methods: {
		closeCookies() {
			localStorage.setItem('cookies', '1');
			this.cookies = false;
		},
	},
	created() {

		this.cookies = !(localStorage.getItem('cookies') && localStorage.getItem('cookies') === '1');
	}
};
</script>
