export default [
	{
		path: '/',
		component: () => import('@/layouts/default.vue'),
		meta: {
			requiresAuth: true,
			approval: true,
		},
		children: [
			{
				path: 'basket',
				component: () => import('@/views/Basket/Index'),
				name: 'BasketIndex',
			},
			{
				path: 'confirmation',
				component: () => import('@/views/Confirmation/Index'),
				name: 'ConfirmationIndex',
			},
			{
				path: 'history',
				component: () => import('@/views/OrderHistory/Index/Index'),
				name: 'OrderHistoryIndex',
			},
			{
				path: 'history/:number',
				component: () => import('@/views/OrderHistory/Show/Index'),
				name: 'OrderHistoryShow',
				props: true,
			},

		]
	},
];
