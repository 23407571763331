export default [
	{
		path: '/',
		component: () => import('../layouts/empty.vue'),
		meta: {
			authAll: false,
		},
		children: [
			{
				path: 'login',
				component: () => import('../views/auth/Login.vue'),
				name: 'Login',
			},
			{
				path: 'register',
				component: () => import('../views/auth/Register.vue'),
				name: 'Register',
			},
			{
				path: 'forgot_password',
				component: () => import('../views/auth/ForgotPassword.vue'),
				name: 'ForgotPassword',
			},
			{
				path: 'change_forgotten_password',
				component: () => import('../views/auth/ChangeForgotPassword.vue'),
				name: 'ChangeForgotPassword',
			},
		]
	},
];
