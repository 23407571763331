import axios from "axios";

export default {


	async index() {
		return await axios.get("/orders");
	},

	async get(id) {
		return await axios.get("/orders/" + id);
	},

	async reorder(id) {
		return await axios.get("/orders/" + id + '/reorder');
	},

	async store(payload) {
		return await axios.post("/orders", payload);
	},
};