import OrderService from "@/services/orders/OrderService";

const state = {
	orders: [],
	order: {},
};

const getters = {
	ORDERS: state => state.orders,
	ORDER: state => state.order,
};

const mutations = {
	ORDERS_SET(state, items) {
		state.orders = items;
	},
	ORDER_SET(state, value) {
		state.order = value;
	},


};

const actions = {
	all({commit}) {
		return new Promise((resolve, reject) => {
			OrderService.index()
				.then((resp) => {
					if (resp.data.success) {
						commit('ORDERS_SET', resp.data.data)
					}
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});
	},


	get({commit}, id) {
		return new Promise((resolve, reject) => {
			OrderService.get(id)
				.then((resp) => {
					if (resp.data.success) {
						commit('ORDER_SET', resp.data.data)
					}
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});
	},

	reorder(state, id) {
		return new Promise((resolve, reject) => {
			OrderService.reorder(id)
				.then((resp) => {
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});
	},

};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
