import SendMessageService from "@/services/SendMessageService";

const actions = {
	sendMessage(state, payload) {
		return new Promise((resolve, reject) => {
			SendMessageService.sendMessage(payload)
				.then((resp) => {
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});
	},
};

export default {
	namespaced: true,
	actions,
};
