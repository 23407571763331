import '@fortawesome/fontawesome-free/css/all.css'
import '@mdi/font/css/materialdesignicons.css'

import Vue from 'vue'
import Vuetify, {
	VApp,
	VAppBar,
	VAlert,
	VAutocomplete,
	VAvatar,
	VBadge,
	VBtn,
	VCard,
	VChip,
	VDataTable,
	VExpansionPanel,
	VDialog,
	VDivider,
	VForm,
	VHover,
	VIcon,
	VImg,
	VInput,
	VMenu,
	VNavigationDrawer,
	VSheet,
	VResponsive,
	VSelect,
	VTextarea,
	VTextField,
	VWindow,
	VSlider,
	VRangeSlider,

	//VBanner,
	//VBottomNavigation,
	//VBottomSheet,
	//VBreadcrumbs,
	//VBtnToggle,
	//VCalendar,
	//VCarousel,
	//VCheckbox,
	//VChipGroup,
	//VColorPicker,
	//VContent,
	//VCombobox,
	//VCounter,
	//VData,
	//VDataIterator,
	//VDatePicker,
	//VFileInput,
	//VFooter,
	//VItemGroup,
	//VLabel,
	//VLazy,
	//VList,
	//VMain,
	//VMessages,
	//VOverflowBtn,
	//VOverlay,
	//VPagination,
	//VParallax,
	//VPicker,
	//VProgressCircular,
	//VProgressLinear,
	//VRadioGroup,
	//VRating,
	//VSkeletonLoader,
	//VSlideGroup,
	//VSnackbar,
	//VSparkline,
	//VSpeedDial,
	//VStepper,
	//VSubheader,
	//VSwitch,
	//VSystemBar,
	//VTabs,
	//VThemeProvider,
	//VTimeline,
	//VTimePicker,
	//VToolbar,
	//VTooltip,
	//VTreeview,
	//VVirtualScroll,
} from 'vuetify/lib'

import {Ripple} from 'vuetify/lib/directives'

import 'vuetify/dist/vuetify.min.css'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

import en from 'vuetify/es5/locale/en';

const opts = {
	lang: {
		locales: {en},
		current: 'en',
	},
	iconfont: 'fa5',
	theme: {
		dark: false,
		options: {
			customProperties: true,
			cspNonce: Date.now(),
		},
		themes: {
			light: {
				primary: '#2196F3',
				secondary: '#1E88E5',
				accent: colors.orange.base,
				error: colors.red.accent2,
				info: colors.blue.base,
				success: colors.green.base,
				warning: colors.orange.base,
				text: '#212b36',
			},
		}
	},
	components: {
		VApp,
		VAppBar,
		VAlert,
		VAutocomplete,
		VAvatar,
		VBadge,
		VBtn,
		VCard,
		VChip,
		VDataTable,
		VExpansionPanel,
		VDialog,
		VDivider,
		VForm,
		VHover,
		VIcon,
		VImg,
		VInput,
		VMenu,
		VNavigationDrawer,
		VSheet,
		VResponsive,
		VSelect,
		VTextarea,
		VTextField,
		VWindow,
		VSlider,
		VRangeSlider,
	},
	directives: {
		Ripple,
	},
}

export default new Vuetify(opts)