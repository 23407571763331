import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import 'babel-polyfill'
import vuetify from '@/plugins/vuetify';
import axios from '@/plugins/axios';
import '@/plugins/Dayjs';
import * as Sentry from "@sentry/vue";
import {HttpClient as HttpClientIntegration, CaptureConsole as CaptureConsoleIntegration} from "@sentry/integrations";
//import {HttpClient as HttpClientIntegration} from "@sentry/integrations";

import _ from 'lodash';

Object.defineProperty(Vue.prototype, '_', {value: _});


import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2, {
	customClass: {
		closeButton: 'v-btn v-btn--contained',
		confirmButton: 'v-btn v-btn--contained',
		cancelButton: 'v-btn v-btn--contained',
	}
});

import VueCurrencyFilter from 'vue-currency-filter'

Vue.use(VueCurrencyFilter, [
	{
		name: 'euro',
		symbol: '€',
		thousandsSeparator: ' ',
		fractionCount: 2,
		fractionSeparator: ',',
		symbolPosition: 'back',
		symbolSpacing: true,
	},
	{
		name: 'euro_small',
		symbol: '€',
		thousandsSeparator: '',
		fractionCount: 2,
		fractionSeparator: ',',
		symbolPosition: 'back',
		symbolSpacing: true,
	},
])

import 'remixicon/fonts/remixicon.css'
import '@/assets/css/style.css';

import VueGtag from "vue-gtag";

if (['local', 'test', 'preprod'].includes(process.env.VUE_APP_API_ENV)) {
	console.log(process.env)
}
Vue.use(VueGtag, {
	config: {id: "GTM-56LDV2R"},
	name: 'B2B',
	includes: [
		{id: 'GTM-M8HH4BB'},
	],
}, router);


if (process.env.VUE_APP_SENTRY_DNS) {
	Sentry.init({
		Vue,
		console: ['local', 'test', 'preprod'].includes(process.env.VUE_APP_API_ENV),
		environment: process.env.VUE_APP_SENTRY_ENV,
		dsn: process.env.VUE_APP_SENTRY_DNS,
		integrations: [
			new Sentry.BrowserTracing({
				tracePropagationTargets: ["*"],
				routingInstrumentation: Sentry.vueRouterInstrumentation(router),
			}),
			new Sentry.Replay(),
			new HttpClientIntegration({
				failedRequestStatusCodes: [[500, 505], 507],
			}),
			new CaptureConsoleIntegration({
				levels: ['error']
			})
		],
		tracesSampleRate: 0.7, // Capture 100% of the transactions, reduce in production!
		replaysSessionSampleRate: 0,
		replaysOnErrorSampleRate: 1,
		sendDefaultPii: true,
		ignoreErrors: [
			'ChunkLoadError'
		],
	});
}


Vue.config.productionTip = false
store.dispatch('AUTH_STORE/autoLogin')
	.then(() => {
		if (store.getters['AUTH_STORE/IS_AUTH']) {
			Vue.$gtag.config({user_id: store.getters['AUTH_STORE/USER'].dmt_id})
		}
		new Vue({
			router,
			store,
			axios,
			vuetify,
			render: h => h(App)
		}).$mount('#app')
	});
