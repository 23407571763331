import axios from "axios";

export default {
	async getBanners() {
		return await axios.get("/banners");
	},


	async getAuthUser() {
		return await axios.get("/user");
	},

	async updatePassword(payload) {
		return await axios.put("/user/password", payload);
	},

	async restorePasswordEmail(payload) {
		return await axios.post("/password_remember", payload);
	},

	async updateForgottenPassword(payload) {
		return await axios.put("/password_forgotten", payload);
	},
};
