import axios from "axios";

export default {
	async index() {
		return await axios.get("/settings/approvals");
	},

	async show(name) {
		return await axios.get("/settings/approvals/" + name);
	},

};