export default [
	{
		path: '/profile',
		component: () => import('@/layouts/default.vue'),
		meta: {
			requiresAuth: true,
			approval: false,
		},
		children: [
			{
				path: '',
				component: () => import('@/views/Profile/Index'),
				name: 'ProfileIndex',
			},

		]
	},
];
