export default [
	{
		path: '/',
		component: () => import('@/layouts/default.vue'),
		meta: {
			requiresAuth: true,
			approval: true,
		},
		children: [
			{
				path: 'faq',
				component: () => import('@/views/Other/OtherFaq'),
				name: 'OtherFaq',
			},

		]
	},
	{
		path: '/',
		component: () => import('@/layouts/pdf.vue'),
		meta: {
			requiresAuth: true,
			approval: true,
		},
		children: [
			{
				path: 'help',
				component: () => import('@/views/Other/OtherHelp'),
				name: 'OtherHelp',
			},
			{
				path: 'dossier',
				component: () => import('@/views/Other/OtherDossier'),
				name: 'OtherDossier',
			},
			{
				path: 'discount-bundles',
				component: () => import('@/views/Other/OtherPromotion'),
				name: 'OtherPromotion',
			},
			{
				path: 'portfolio',
				component: () => import('@/views/Other/OtherPortfolio'),
				name: 'OtherPortfolio',
			},
			{
				path: 'special-offers',
				component: () => import('@/views/Other/SpecialOffers'),
				name: 'SpecialOffers',
			},

		]
	},
];
