import NotificationService from "@/services/orders/NotificationService";

const state = {
	items: [],
	dialog: false,
	waiting: false,
};

const getters = {
	ITEMS: state => state.items,
	DIALOG: state => state.dialog,
	WAINING: state => state.waiting,
};

const mutations = {
	ITEMS_SET(state, value) {
		state.items = value;
	},
	WAINING_SET(state, value) {
		state.waiting = value;
	},
	SHOW_SET(state) {
		state.items = state.items.map(i => {
			i.show = false;
			return i;
		});
	},
	DIALOG_SET(state, value) {
		state.dialog = value;
	},
};

const actions = {
	getAll({commit, getters, rootGetters}) {
		if (!getters.DIALOG && rootGetters["AUTH_STORE/USER"]){
			return new Promise((resolve, reject) => {
				NotificationService.index()
					.then((resp) => {
						if (resp.data.success && !getters.DIALOG) {
							commit('ITEMS_SET', resp.data.data);
						}
						resolve(resp);
					})
					.catch(err => {
						reject(err);
					});
			});
		}

	},
	showed() {
		return new Promise((resolve, reject) => {
			NotificationService.showed()
				.then((resp) => {
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
