import axios from "axios";

export default {
	async index() {
		return await axios.get("/baskets");
	},

	async update(payload) {
		return await axios.put("/baskets", payload);
	},

};