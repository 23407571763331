import Vue from 'vue'
import Vuex from 'vuex'

/* LAYOUT */
import LAYOUT_CONTENT_STORE from './layout/contentStore';
import LAYOUT_NAV_STORE from './layout/navStore';

/*SETTINGS*/
import SETTINGS_APPROVALS from './settings/approvalsStore.js';

/*USER*/
import AUTH_STORE from './user/authStore.js';
import USER_SETTINGS from './user/settingsStore.js';
import REGISTER_STORE from './user/registerStore.js';
import RESTORE_PASSWORD_STORE from './user/restorePasswordStore.js';

/*DASHBOARD*/
import DASHBOARD_STORE from './dashboard/dashboardStore.js';
import BASKET_STORE from './dashboard/basketStore.js';
import ORDER_HISTORY_STORE from './dashboard/orderHistoryStore.js';
import NOTIFICATION_STORE from './dashboard/notificationStore.js';

/*PROFILE*/
import PROFILE_CLIENT_STORE from './profile/clientStore.js';
import PROFILE_ADDRESS_STORE from './profile/addressStore.js';
import PROFILE_CONTACT_DETAIL_STORE from './profile/contactDetailStore.js';
import PROFILE_BILLING_DETAIL_STORE from './profile/billingDetailStore.js';


import SEND_MESSAGE_STORE_STORE from './sendMessageStore.js';


Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production';
export default new Vuex.Store({
	modules: {

		/* LAYOUT */
		LAYOUT_CONTENT_STORE,
		LAYOUT_NAV_STORE,

		/* SETTINGS */
		SETTINGS_APPROVALS,

		/*USER*/
		AUTH_STORE,
		USER_SETTINGS,
		REGISTER_STORE,
		RESTORE_PASSWORD_STORE,

		/*DASHBOARD*/
		DASHBOARD_STORE,
		BASKET_STORE,
		ORDER_HISTORY_STORE,
		NOTIFICATION_STORE,

		/*PROFILE*/
		PROFILE_CLIENT_STORE,
		PROFILE_ADDRESS_STORE,
		PROFILE_CONTACT_DETAIL_STORE,
		PROFILE_BILLING_DETAIL_STORE,

		SEND_MESSAGE_STORE_STORE,

	},
	strict: debug,
});
