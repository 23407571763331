import BillingDetailService from "@/services/profile/BillingDetailService";
import _ from 'lodash';

const state = {
};

const getters = {
};

const mutations = {};

const actions = {

	store({commit}, payload) {
		return new Promise((resolve, reject) => {
			payload.country = payload.country.iso2;
			BillingDetailService.store(payload)
				.then((resp) => {
					if (resp.data.success) {
						commit('PROFILE_CLIENT_STORE/UPDATE_VALUE_SET', {
							name: 'billing_details',
							value: resp.data.data
						}, {root: true});
					}
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});
	},

	update({commit}, {id, payload}) {
		return new Promise((resolve, reject) => {
			payload.country = payload.country.iso2;
			BillingDetailService.update(id, payload)
				.then((resp) => {
					if (resp.data.success) {
						commit('PROFILE_CLIENT_STORE/UPDATE_VALUE_SET', {
							name: 'billing_details',
							value: resp.data.data
						}, {root: true});
					}
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});
	},

	setAsDefault({commit, rootGetters}, id) {
		return new Promise((resolve, reject) => {
			let payload = _.cloneDeep(rootGetters['PROFILE_CLIENT_STORE/CLIENT'].billing_details.find(i => i.id === id))
			payload.country = payload.country.iso2;
			payload.primary = true;

			BillingDetailService.update(id, payload)
				.then((resp) => {
					if (resp.data.success) {
						commit('PROFILE_CLIENT_STORE/UPDATE_VALUE_SET', {
							name: 'billing_details',
							value: resp.data.data
						}, {root: true});
					}
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});
	},

	destroy({commit}, id) {
		return new Promise((resolve, reject) => {
			BillingDetailService.delete(id)
				.then((resp) => {
					if (resp.data.success) {
						commit('PROFILE_CLIENT_STORE/UPDATE_VALUE_SET', {
							name: 'billing_details',
							value: resp.data.data
						}, {root: true});
					}
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});
	},

};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
