export default [
	{
		path: '/info',
		component: () => import('../layouts/empty.vue'),
		meta: {
			authAll: false,
			approval: false,
		},
		children: [
			{
				path: 'privacy-policy',
				component: () => import('../views/Info/PrivacyPolicy.vue'),
				name: 'InfoPrivacyPolicy',
			},
			{
				path: 'terms',
				component: () => import('../views/Info/Terms.vue'),
				name: 'InfoTerms',
			},
		]
	},
];
