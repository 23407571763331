import ApprovalService from "@/services/settings/ApprovalService";

const state = {};

const getters = {};

const mutations = {};

const actions = {
	index() {
		return new Promise((resolve, reject) => {
			ApprovalService.index()
				.then(resp => {
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});
	},
	show(state, payload) {
		return new Promise((resolve, reject) => {
			ApprovalService.show(payload)
				.then(resp => {
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});
	},

};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
